.show-on-hover {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.show-child-on-hover:hover .show-on-parent-hover {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
  overflow: visible !important;
}

.show-on-parent-hover {
  visibility: hidden;
  opacity: 0;
}