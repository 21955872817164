@import "src/common/colors";

.wrapper {
  .navigation {
    display: flex;
    align-items: center;
    margin-top: 20px;

    :global(.btn):not(:first-child) {
      margin-left: 10px;
    }
  }

  .error {
    padding: 5px 0;
    text-align: center;
    color: $red;
  }
}
