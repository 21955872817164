@import "src/common/colors";

.reservationBlockFull {
  position: absolute;
  font-size: 1em;
  box-shadow: 0 0 4rem #929ab7;
}

.leftShadow {
  box-shadow: -10px 0px 10px 1px #ffffff;
}
