@import "src/common/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .content {
    flex: 1;
  }

  .summary {
    flex-shrink: 0;
  }

  @media (min-width: 768px) {
    flex-direction: row;

    .summary {
      margin-left: 30px;
      width: 300px;
    }
  }
}
