$header-start-color: #2e86dd;
$header-end-color: #1c68a3;

$white: #ffffff;
$grey: #eff3f9;
$green: #00ac69;
$blue: #0061f2;
$red: #e81500;
$dark: #1f2d41;

$transparentBlue: #3744582b;