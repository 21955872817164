@import "src/common/colors";

.react-calendar {
  border-radius: 0.3em;
  border: none;
  margin-top: .3em;
}

.react-calendar__tile--now {
  background: rgba(0,0,0,0.05);
  outline: 2px solid #006edcb0 !important;
  z-index: 1;
}

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
  background: #e6e6e6 !important;
}

.react-calendar__tile--active {
  background: #006edc;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: #0056ae !important;
}

.react-calendar__tile:disabled {
  background-color: #e1e1e1;
  color: #b3b3b3 !important;
}

.react-date-picker__wrapper {
  border: none;
  align-items: center;
}

.react-calendar button {
  border-radius: 0.3em;
}

