@import "./src/common/colors";

.openingHours {
  &:hover {
    background: rgba(0,0,0,0.02);
  }
  
  & :global(.custom-control-solid) label:before {
    border-color: $transparentBlue;
    border-width: 2px;
  }
}

.pauseTimes {
  opacity: 0.7;
}

.timesHelpIcon {
  width: 1em !important;
}