.overlay-container {
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(146, 146, 146, 0.75);
  width: 100%;
  z-index: 2000;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.overlay-container .container-fluid {
  padding-right: 0 !important;
}