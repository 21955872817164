@import "src/common/colors";

.wrapper {
  border-radius: 4px;
  border: 2px solid $blue;
  padding: 15px;

  .title {
    font-size: 20px;
    margin-bottom: 20px;
    color: $blue;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    .name {
      font-weight: 500;
    }
  }
}
